<template>
  <div class="w-100">
    <b-form-group
      :id="id + 'group'"
      :label="computedLabel"
      :label-for="id"
      :label-sr-only="!displayLabel"
      class="w-100 mb-0"
      :class="computedLabelAlignment"
      :label-cols-sm="computedLabelColsSM"
      :label-cols-lg="computedLabelColsLG"
    >
      <date-range-picker
        class="w-100 px-0 text-left"
        :id="id"
        :name="id"
        v-model="value"
        :state="validateState()"
        :ref="id + '-picker'"
        opens="center"
        :locale-data="{ firstDay: 1, format: 'yyyy/mm/dd' }"
        :minDate="calculatedMinDate"
        :maxDate="calculatedMaxDate"
        :placeholder="computedPlaceholder"
        :disabled="computedDisabled"
        :auto-apply="true"
        @update="dateRangeUpdated()"
      >
        <template style="min-width: 350px;">
          {{ lowerRange }} - {{ upperRange }}
        </template>
        <template #input="" style="min-width: 350px;">
          <p v-if="displayPlaceholder" class="mb-0">
            {{ calculatedInput }}
          </p>
          <div v-else class="d-flex justify-content-between">
            <p class="mb-0">
              {{ calculatedInput }}
            </p>
            <a
              class="ml-3"
              v-if="calculatedClearable"
              href="javascript:void(0)"
              v-on:mouseover="clearing = true"
              v-on:mouseleave="clearing = false"
              @click="clearSelection"
            >
              <i class="fas fa-times pt-1" style="font-size:.925rem"></i>
            </a>
          </div>
        </template>
      </date-range-picker>
      <div
        class="custom-validation-failure mb-7"
        v-if="model.$anyError && displayError"
      >
        {{ error }}
      </div>
    </b-form-group>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "GenericDateRangePicker",

  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    label: { type: String, required: false },
    alignLabel: { type: String, required: false },
    placeholder: { type: String, required: false},
    error: { type: String, required: false},
    showInline: { type: Boolean, required: true },
    submissionStates: { type: Object, required: true },
    minDate: { type: String, required: false },
    maxDate: { type: String, required: false },
    clearable: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false }
  },

  data() {
    return {
      clearing: false,
      value: {
        startDate: null,
        endDate: null
      }
    }
  },

  components: { DateRangePicker },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    },
    clearSelection: function() {
      if (this.clearable) {
        this.$emit('selection_cleared');
        this.clearing = false;
      }
    },
    convertFromUTCTimezone(date, format) {
      if (this.currentUser.system_timezone_setting_id == 1) {
        return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").local().format(format);
      } else if (this.currentUser.system_timezone_setting_id == 2) {
        if (this.projectInfo.systemTimezone != null) {
          return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").utcOffset(this.projectInfo.systemTimezone.offset).format(format);
        } else {
          return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").local().format(format);
        };
      } else {
        return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").utcOffset(this.currentUser.systemTimezone.offset).format(format);
      }
    },
    convertToUTCTimezone(date, format, addHours = 0, subtractHours = 0, subtractSeconds = 0) {
      if (this.currentUser.system_timezone_setting_id == 1) {
        return moment.
          utc(date, "YYYY-MM-DD HH:mm:ss Z").
          local().
          add(addHours,'hours').
          subtract(subtractHours,'hours').
          subtract(subtractSeconds,'seconds').
          format(format);
      } else if (this.currentUser.system_timezone_setting_id == 2) {
        if (this.projectInfo.systemTimezone != null) {
          return moment.
          utc(date, "YYYY-MM-DD HH:mm:ss Z").
          utcOffset(this.calculatedReverseOffset).
          add(addHours,'hours').
          subtract(subtractHours,'hours').
          subtract(subtractSeconds,'seconds').
          format(format);
        } else {
          return moment.
          utc(date, "YYYY-MM-DD HH:mm:ss Z").
          local().
          add(addHours,'hours').
          subtract(subtractHours,'hours').
          subtract(subtractSeconds,'seconds').
          format(format);
        };
      } else {
        return moment.
        utc(date, "YYYY-MM-DD HH:mm:ss Z").
        utcOffset(this.calculatedReverseOffset).
        add(addHours,'hours').
        subtract(subtractHours,'hours').
        subtract(subtractSeconds,'seconds').
        format(format);
      }
    },
    dateRangeUpdated() {
      let dates = {
        offsetDates: {
          startDate: this.value.startDate,
          endDate: this.value.endDate
        },
        utcDates: {
          startDate: null,
          endDate: null
        },
        upperRange: this.upperRange,
        lowerRange: this.lowerRange
      }

      dates.utcDates.startDate = this.convertToUTCTimezone(this.value.startDate, "ddd MMM DD YYYY HH:mm:ss", 0, 12, 0);
      dates.utcDates.endDate = this.convertToUTCTimezone(this.value.endDate, "ddd MMM DD YYYY HH:mm:ss", 12, 0, 1);

      if (dates.utcDates.endDate == dates.utcDates.startDate) {
        dates.utcDates.endDate = moment.utc(dates.utcDates.endDate, "ddd MMM DD YYYY HH:mm:ss").add(24,'hours').format('ddd MMM DD YYYY HH:mm:ss');
      }

      // let projectStart = moment.utc(this.projectInfo.starts_on, "YYYY-MM-DD HH:mm:ss").format('ddd MMM DD YYYY HH:mm:ss');
      let currentDate = moment.utc().format('ddd MMM DD YYYY HH:mm:ss');

      // removing this for the sake  of legacy review
      // if (dates.utcDates.startDate < projectStart) {
      //   dates.utcDates.startDate = projectStart;
      // };

      if (dates.utcDates.endDate > currentDate) {
        dates.utcDates.endDate = currentDate;
      };
    
      this.$emit('update', dates);
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo"
    ]),
    computedDisabled: function() {
      if (this.disabled == true || this.clearing == true) {
        return true;
      } else {
        return false;
      };
    },
    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayError: function() {
      if (this.error != undefined) {
        if (this.error.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayPlaceholder: function() {
      if (this.placeholder != undefined) {
        if (this.placeholder.length > 0) {
          if (this.model.$model.startDate == null && this.model.$model.endDate == null) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    computedLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          if (this.model.$params.hasOwnProperty('required')) {
            return this.label + "*";
          } else {
            return this.label;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    computedPlaceholder: function() {
      if (this.placeholder != undefined) {
        if (this.placeholder.length > 0) {
          return this.placeholder;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    computedLabelColsSM: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 4;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelColsLG: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 3;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelAlignment: function() {
      if (this.alignLabel != undefined) {
        if (this.alignLabel == "right") {
          return "text-right";
        } else if (this.alignLabel == "center") {
          return "text-center";
        } else {
          return "text-left";
        }
      } else {
        return "text-left";
      }
    },
    lowerRange: function() {
      if (this.value.startDate != null) {
        return moment.utc(this.value.startDate, "ddd MMM DD YYYY HH:mm:ss Z").format("YYYY/MM/DD");
      } else {
        return null;
      }
    },
    upperRange: function() {
      if (this.value.endDate != null) {
        return moment.utc(this.value.endDate, "ddd MMM DD YYYY HH:mm:ss Z").format("YYYY/MM/DD");
      } else {
        return null;
      }
    },
    calculatedMinDate: function() {
      if (this.minDate != undefined) {
        let utcDate = this.minDate + ' +0000'
        return this.convertFromUTCTimezone(utcDate, "YYYY-MM-DD") + ' 00:00:00';
      } else {
        return "null";
      }
    },
    calculatedMaxDate: function() {
      if (this.maxDate != undefined) {
        let utcDate = this.maxDate + ' +0000'
        return this.convertFromUTCTimezone(utcDate, "YYYY-MM-DD") + ' 00:00:00';
      } else {
        let utcDate = moment.utc().format('ddd MMM DD YYYY HH:mm:ss Z');
        return this.convertFromUTCTimezone(utcDate, "YYYY-MM-DD") + ' 00:00:00';
      }
    },
    calculatedInput: function() {
      if (this.model.$model.startDate == null && this.model.$model.endDate == null) {
        return this.computedPlaceholder;
      } else {
        return this.lowerRange + ' - ' + this.upperRange;
      }
    },
    calculatedClearable: function() {
      if (this.clearable != undefined) {
        if (this.clearable) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    calculatedReverseOffset: function() {
      let originalOffset = this.convertFromUTCTimezone(this.value, "Z");
      
      if (originalOffset.includes('+')) {
        return originalOffset.replace('+','-');
      } else {
        return originalOffset.replace('-','+');
      };
    }
  }
}
</script>

